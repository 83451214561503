import { useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Tabs, TypographyButton } from '@components';
import { useLoadYotpo } from '@services/yotpo';
import { capitalizeEachWord } from '@utils/strings';
import styles from './AccountTabs.module.scss';

type AccountTabsProps = {
	defaultValue: string;
};

const AccountTab = ({ activeTab, tabName }) => {
	const ref = useRef(null);
	const router = useRouter();

	const scrollIntoView = () => ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

	useEffect(() => {
		if (ref?.current && activeTab === tabName) {
			scrollIntoView();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref?.current, activeTab]);

	return (
		<Tabs.Trigger
			asChild
			ref={ref}
			value={tabName}
			onClick={() => {
				scrollIntoView();
				router.push(tabName !== 'rewards' ? `/account/${tabName}` : `/${tabName}`, undefined, { shallow: true });
			}}
		>
			<TypographyButton small>My {capitalizeEachWord(tabName)}</TypographyButton>
		</Tabs.Trigger>
	);
};

const AccountTabs = ({ defaultValue = 'collection' }: AccountTabsProps) => {
	useLoadYotpo();
	const { locale } = useRouter();

	const showRewards = locale === 'en-US' || locale === 'en-CA';
	const tabs = showRewards ? ['favorites', 'collection', 'orders', 'rewards'] : ['favorites', 'collection', 'orders'];

	return (
		<Tabs value={defaultValue} activationMode='manual' className={styles.list}>
			<Tabs.List>
				{tabs.map(tabName => (
					<AccountTab key={tabName} activeTab={defaultValue} tabName={tabName} />
				))}
			</Tabs.List>
		</Tabs>
	);
};

export default AccountTabs;
